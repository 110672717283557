import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import * as actions from "../../redux/actions"
import { connect } from "react-redux"
import Styled from "styled-components"

import { ROUTES, DEVICES, URLS } from "../../config"
import ExternalLink from "../ExternalLink"
import { Facebook, Instagram } from "../../components/Common/fontawesome"

const Footer = Styled.footer`
    font-size: 1rem;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.onBackground};
    position: relative;
    padding-top: 20px;
    margin-top: 100px;
    bottom: 0;
`
const FooterLeft = Styled.div`
    display: flex;
    justify-content: flex-start !important;
    ul {
        padding-left: 0px;
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        li {
            a {
              svg {
                color: ${({ theme }) => theme.onBackground};
                margin-right: 10px;
                align-self: center;
                width: 1.1rem;
                &:hover {
                  text-decoration: none;
                  color: ${({ theme }) => theme.primary};
                }
              }
            }
        }
        li:last-child {
          margin-right: 0px;
        }
    }
    @media all and ${DEVICES.maxTablet} {
        justify-content: center !important;
    }
`
const FooterRight = Styled.div`
    display: flex;
    justify-content: flex-end !important;
    ul {
        padding-left: 0px;
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        li {
            font-family: ${({ theme }) => theme.fontSecondary};
            a {
                text-decoration: none;
                margin-left: 10px;
                color: ${({ theme }) => theme.onBackground};
                &:hover {
                  text-decoration: none;
                  color: ${({ theme }) => theme.primary};
                }
            }
        }
    }
    @media all and ${DEVICES.maxTablet} {
        justify-content: center !important;
    }
    @media all and ${DEVICES.maxMobileL} {
        ul {
            flex-direction: column;
            width: 100%;
            text-align: center;
            li {
                padding-top: 10px;
                width: 100%;
                border-top: ${({ theme }) => `1px solid ${theme.onBackground}`};
            }
        }
    }
`

const FooterBottom = Styled.div`
    display: flex;
    font-family: ${({ theme }) => theme.fontSecondary};
    justify-content: center;
    width: 100%;
    border-top: ${({ theme }) => `1px solid ${theme.onBackground}`};
    padding: 10px;
    color: ${({ theme }) => theme.onBackground};
`

const MyFooter = ({ display }) => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      site {
        buildTime(formatString: "MM/DD/YYYY")
      }
    }
  `)
  let className = "container-fluid mr-0 ml-0"
  if (!display) {
    className += "d-none"
  }
  return (
    <Footer className={className}>
      <div className="row">
        <FooterLeft className="col-md-3">
          <ul>
            <li>
              <ExternalLink href={URLS.INSTAGRAM}>
                <Instagram />
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href={URLS.FACEBOOK}>
                <Facebook />
              </ExternalLink>
            </li>
          </ul>
        </FooterLeft>
        <FooterRight className="col-md-9">
          <ul>
            {ROUTES.map(route => {
              if (route.link) {
                return (
                  <li key={`${route.name}-menu`}>
                    <ExternalLink href={`${route.link}`}>
                      {route.name}
                    </ExternalLink>
                  </li>
                )
              }
              return (
                <li key={`${route.name}-menu`}>
                  <Link to={`${route.path}`}>{route.name}</Link>
                </li>
              )
            })}
          </ul>
        </FooterRight>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <FooterBottom>
            <span>
              &copy; {new Date().getFullYear()} Beyond The Apprenticeship | Last
              Build {data.site.buildTime}
            </span>
          </FooterBottom>
        </div>
      </div>
    </Footer>
  )
}

const MapStateToProps = state => {
  return { authenticated: state.auth.authenticated }
}

export default connect(MapStateToProps, actions)(MyFooter)
