import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Styled from "styled-components"

import ExternalLink from "../../ExternalLink"
import { ROUTES } from "../../../config"

const Menu = Styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.background};
  height: 100vh;
  padding: 2rem;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`
const MenuLogo = Styled(Img)`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 120px;
`
const MenuItems = Styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;
  li {
    font-family: ${({ theme }) => theme.fontSecondary};
    list-style: none;
    align-self: center;
    a {
      text-decoration: none;
      text-transform: uppercase;
      height: 100%;
      color: ${({ theme }) => theme.onBackground};
      font-size: 2rem;
      font-weight: 500;
      transition: color 0.3s linear;
      &:hover {
        text-decoration: underline;
        color: ${({ theme }) => theme.primary};
      }
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 1.5rem;
        text-align: center;
      }
    }
  }
`
const MyMenu = ({ open, isDark }) => {
  const data = useStaticQuery(graphql`
    query menuQuery {
      iconBlackShadow: allFile(
        filter: { name: { eq: "bta_logo_icon_b_shadow_600x601" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
      iconWhiteShadow: allFile(
        filter: { name: { eq: "bta_logo_icon_w_shadow_600x601" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)
  const iconBlackShadow =
    data.iconBlackShadow.edges[0].node.childImageSharp.fluid
  const iconWhiteShadow =
    data.iconWhiteShadow.edges[0].node.childImageSharp.fluid
  return (
    <Menu open={open}>
      <MenuItems>
        <li>
          <Link to="/">
            {isDark ? (
              <MenuLogo
                fluid={iconWhiteShadow}
                alt="BTA Icon Black Shadow"
                imgStyle={{ objectFit: "contain" }}
              />
            ) : (
              <MenuLogo
                fluid={iconBlackShadow}
                alt="BTA Icon Black Shadow"
                imgStyle={{ objectFit: "contain" }}
              />
            )}
          </Link>
        </li>
        {ROUTES.map(route => {
          if (route.link) {
            return (
              <li key={`${route.name}-menu`}>
                <ExternalLink href={`${route.link}`}>{route.name}</ExternalLink>
              </li>
            )
          }
          return (
            <li key={`${route.name}-menu`}>
              <Link to={`${route.path}`}>{route.name}</Link>
            </li>
          )
        })}
      </MenuItems>
    </Menu>
  )
}

export default MyMenu
