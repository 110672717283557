import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import { ToastProvider } from "../Toasts"
import { useDarkMode } from "../Hooks"

// Master CSS Import
import "../../styles/styles.css"
// Styled Components Theme/Global
import { GlobalStyles, DefaultTheme, DarkTheme } from "../../styles"
// Main Layout Components
import Nav from "../Nav"
import Header from "../Header"
import AnimatedHeader from "../Header/AnimatedHeader"
import Footer from "../Footer"

const Layout = ({
  fluid,
  alt,
  height,
  heightMobile,
  h1,
  imgDark,
  imgLight,
  children,
}) => {
  useEffect(() => {
    // remove dim on page change
    if (
      document.getElementById("dimmer-overlay").classList.contains("dimmer")
    ) {
      document.getElementById("dimmer-overlay").classList.toggle("dimmer")
    }
  }, [])
  const [isDark, toggleIsDark] = useDarkMode()
  const renderHeader = () => {
    if (fluid) {
      return (
        <Header
          fluid={fluid}
          alt={alt}
          height={height}
          heightMobile={heightMobile}
          h1={h1}
          img={isDark ? imgDark : imgLight}
        />
      )
    }
    return (
      <AnimatedHeader
        height={height}
        heightMobile={heightMobile}
        h1={h1}
        img={isDark ? imgDark : imgLight}
      />
    )
  }
  return (
    <ThemeProvider theme={isDark ? DarkTheme : DefaultTheme}>
      <GlobalStyles />
      <Nav isDark={isDark} toggleIsDark={toggleIsDark} />
      {renderHeader()}
      <ToastProvider>
        <div className="container-fluid mt-5">
          <main>{children}</main>
        </div>
      </ToastProvider>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
