import React from "react"
import Styled from "styled-components"

const Ruled = Styled.h1`
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
    font-family: ${({ theme }) => theme.fontPrimary};
    @media screen and (max-width: 600px) {
        font-size: 2rem;
    }
    &::before {
        margin-left: -51%;
        text-align: right;
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 50%;
        height: 1px;
        content: '';
        background-color: ${({ theme }) => theme.onBackground};;
    }
    &::after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 50%;
        height: 1px;
        content: '';
        background-color: ${({ theme }) => theme.onBackground};;
        margin-left: 2%;
    }
`
const RuledBreak = ({ children }) => (
  <Ruled className="fragment-core">{children}</Ruled>
)

export default RuledBreak
