import React from "react"
import { Link } from "gatsby"
import Styled from "styled-components"
/**
 * Base Button Styles
 */
const LinkBase = Styled(Link)`
  font-family: ${({ theme }) => theme.fontPrimary};
  letter-spacing: 5px;
  z-index: 98;
  display: inline-block;
  position: relative;
  border: none;
  color: ${({ theme }) => theme.onBackground};
  background: rgba(0,0,0,0.1);
  font-size: 35px;
  cursor: pointer;
  &:focus {
    outline:0;
  }
`
const ButtonBase = Styled.button`
  font-family: ${({ theme }) => theme.fontPrimary};
  letter-spacing: 5px;
  z-index: 98;
  display: inline-block;
  position: relative;
  border: none;
  color: ${({ theme }) => theme.onBackground};
  background: rgba(0,0,0,0.1);
  font-size: 35px;
  cursor: pointer;
  &:focus {
    outline:0;
  }
`
/**
 * Button Styles 1-2-3
 */
const Button1 = Styled(LinkBase)`
    &::before {
        right: 0;
        top: 0;
    }
    &::after {
        left: 0;
        bottom: 0;
    }
    &::before, &::after {
        content:"";
        width: 0;
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: ${({ theme }) => theme.primary};
        transition-delay: 0.2s;
    }
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.primary};
    }
    &:hover::before, &:hover::after{
        width: 100%;
        transition-delay: 0s;
    }
    &:hover span::before, &:hover span::after {
        height: 100%;
        transition-delay: 0.2s;
    }
     &:active {
        background: ${({ theme }) => `${theme.primary} !important`};
    }
    span {
        z-index: 99;
        display: block;
        padding: 7px 14px;
        &::before {
            left: 0;
            top: 0;
        }
        &::after {
            right: 0;
            bottom: 0;
        }
        &::before, &::after {
            content:"";
            width:2px;
            height:0;
            position: absolute;
            transition: all 0.2s linear;
            transition-delay: 0s;
            background: ${({ theme }) => theme.primary};
        }
        &:active {
            color: ${({ theme }) => `${theme.background} !important`};
        }
    }
`
const Button1b = Styled(ButtonBase)`
    &::before {
        right: 0;
        top: 0;
    }
    &::after {
        left: 0;
        bottom: 0;
    }
    &::before, &::after {
        content:"";
        width: 0;
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: ${({ theme }) => theme.primary};
        transition-delay: 0.2s;
    }
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.primary};
    }
    &:hover::before, &:hover::after{
        width: 100%;
        transition-delay: 0s;
    }
    &:hover span::before, &:hover span::after {
        height: 100%;
        transition-delay: 0.2s;
    }
     &:active {
        background: ${({ theme }) => `${theme.primary} !important`};
    }
    span {
        z-index: 99;
        display: block;
        padding: 7px 14px;
        &::before {
            left: 0;
            top: 0;
        }
        &::after {
            right: 0;
            bottom: 0;
        }
        &::before, &::after {
            content:"";
            width:2px;
            height:0;
            position: absolute;
            transition: all 0.2s linear;
            transition-delay: 0s;
            background: ${({ theme }) => theme.primary};
        }
        &:active {
            color: ${({ theme }) => `${theme.background} !important`};
        }
    }
`
const Button2 = Styled(LinkBase)`
    &::before {
        left: 50%;
        top: 0;
        transition-duration: 0.4s;
    }
    &::after {
        left: 50%;
        bottom: 0;
        transition-duration: 0.4s;
    }
    &::before, &::after {
        content:"";
        width: 0;
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: ${({ theme }) => theme.primary};
    }
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.primary};
    }
    &:hover::before, &:hover::after {
        width: 100%;
        left: 0;
    }
    &:hover span::before, &:hover span::after {
        height: 100%;
        top: 0;
    }
    &:active {
        background: ${({ theme }) => `${theme.primary} !important`};
    }
    span {
        z-index: 99;
        display: block;
        padding: 7px 14px;
        &::before {
            left: 0;
            top: 50%;
            transition-duration: 0.4s;
        }
        &::after {
            right: 0;
            top: 50%;
            transition-duration: 0.4s;
        }
        &::before, &::after {
            content:"";
            width:2px;
            height:0;
            position: absolute;
            transition: all 0.2s linear;
            background: ${({ theme }) => theme.primary};
        }
        &:active {
          color: ${({ theme }) => `${theme.background} !important`};
        }
    }
`
const Button2b = Styled(ButtonBase)`
    &::before {
        left: 50%;
        top: 0;
        transition-duration: 0.4s;
    }
    &::after {
        left: 50%;
        bottom: 0;
        transition-duration: 0.4s;
    }
    &::before, &::after {
        content:"";
        width: 0;
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: ${({ theme }) => theme.primary};
    }
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.primary};
    }
    &:hover::before, &:hover::after {
        width: 100%;
        left: 0;
    }
    &:hover span::before, &:hover span::after {
        height: 100%;
        top: 0;
    }
    &:active {
        background: ${({ theme }) => `${theme.primary} !important`};
    }
    span {
        z-index: 99;
        display: block;
        padding: 7px 14px;
        &::before {
            left: 0;
            top: 50%;
            transition-duration: 0.4s;
        }
        &::after {
            right: 0;
            top: 50%;
            transition-duration: 0.4s;
        }
        &::before, &::after {
            content:"";
            width:2px;
            height:0;
            position: absolute;
            transition: all 0.2s linear;
            background: ${({ theme }) => theme.primary};
        }
        &:active {
          color: ${({ theme }) => `${theme.background} !important`};
        }
    }
`
const Button3 = Styled(LinkBase)`
    background: transparent;
    &::before {
        right: 0;
        top: 0;
    }
    &::after {
        left: 15%;
        bottom: 0;
        width: 70% !important;
    }
    &::before, &::after {
        content:"";
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: ${({ theme }) => theme.primary};
        transition-delay: 0.4s;
    }
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.primary};
    }
    &:hover::before {
        width: 100% !important;
        transition-delay: 0s;
    }
    &:hover::after {
        left: 0;
        width: 100% !important;
        transition-delay: 0s;
    }
    &:hover span::before, &:hover span::after {
        height: 100%;
        transition-delay: 0.2s;
    }
    &:active {
        background: ${({ theme }) => `${theme.primary} !important`};
    }
    span {
        z-index: 99;
        display: block;
        padding: 7px 14px;
        &::before {
            left: 0;
            top: 0;
        }
        &::after {
            right: 0;
            bottom: 0;
        }
        &::before, &::after {
            content:"";
            width: 2px;
            height: 0px;
            position: absolute;
            transition: all 0.2s linear;
            transition-delay: 0s;
            background: ${({ theme }) => theme.primary};
        }
        &:active {
          color: ${({ theme }) => `${theme.background} !important`};
        }
    }
`
const Button3b = Styled(ButtonBase)`
    background: transparent;
    &::before {
        right: 0;
        top: 0;
    }
    &::after {
        left: 15%;
        bottom: 0;
        width: 70% !important;
    }
    &::before, &::after {
        content:"";
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: ${({ theme }) => theme.primary};
        transition-delay: 0.4s;
    }
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.primary};
    }
    &:hover::before {
        width: 100% !important;
        transition-delay: 0s;
    }
    &:hover::after {
        left: 0;
        width: 100% !important;
        transition-delay: 0s;
    }
    &:hover span::before, &:hover span::after {
        height: 100%;
        transition-delay: 0.2s;
    }
    &:active {
        background: ${({ theme }) => `${theme.primary} !important`};
    }
    span {
        z-index: 99;
        display: block;
        padding: 7px 14px;
        &::before {
            left: 0;
            top: 0;
        }
        &::after {
            right: 0;
            bottom: 0;
        }
        &::before, &::after {
            content:"";
            width: 2px;
            height: 0px;
            position: absolute;
            transition: all 0.2s linear;
            transition-delay: 0s;
            background: ${({ theme }) => theme.primary};
        }
        &:active {
          color: ${({ theme }) => `${theme.background} !important`};
        }
    }
`
const ButtonTrace = ({ children, to, onClick, className, btn }) => {
  if (onClick) {
    switch (btn) {
      case 1:
        return (
          <Button1b className={className} onClick={onClick}>
            <span>{children}</span>
          </Button1b>
        )
      case 2:
        return (
          <Button2b className={className} onClick={onClick}>
            <span>{children}</span>
          </Button2b>
        )
      case 3:
        return (
          <Button3b className={className} onClick={onClick}>
            <span>{children}</span>
          </Button3b>
        )
      default:
        return (
          <Button1b className={className} onClick={onClick}>
            <span>{children}</span>
          </Button1b>
        )
    }
  }
  switch (btn) {
    case 1:
      return (
        <Button1 to={to} className={className}>
          <span>{children}</span>
        </Button1>
      )
    case 2:
      return (
        <Button2 to={to} className={className}>
          <span>{children}</span>
        </Button2>
      )
    case 3:
      return (
        <Button3 to={to} className={className}>
          <span>{children}</span>
        </Button3>
      )
    default:
      return (
        <Button1 to={to} className={className}>
          <span>{children}</span>
        </Button1>
      )
  }
}

export default ButtonTrace
